<template>
        <main-template>
            <template v-slot:headerLottieDark>
                <dotlottie-player :src="getDarkLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:headerLottieLight>
                <dotlottie-player :src="getLightLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:header>Base
<!--                <dotlottie-wc src="https://lottie.host/4db68bbd-31f6-4cd8-84eb-189de081159a/IGmMCqhzpt.lottie" no-autoplay loop></dotlottie-wc>-->
<!--                <script type="module" src="https://unpkg.com/@lottiefiles/dotlottie-wc@latest/dist/dotlottie-wc.js"></script>-->

            </template>
            <template v-slot="slotProps">
            <fetch-siren-root :linkName='linkName' :link="link">

                <div slot-scope="{ response }" class="flex flex-grow overflow-hidden">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <div class="flex w-full overflow-hidden">
                            <div class="p-2">
                                <Hr-navigation :links="slotProps.links"/>
                            </div>
                            <div class="flex w-full h-full p-2">
                                <div class="rounded-xl bg-v3-base-500 w-full h-full margin-10 dark:bg-v3-gray-900 overflow-y-auto">
                                    <default-dashboard></default-dashboard>
                                </div>
                            </div>
                        </div>


                    </template>
                </div>
            </fetch-siren-root>
            </template>
        </main-template>
</template>



<script>
import FetchSirenRoot from '@/components/FetchSirenRoot';
import Loading from '@/components/Loading';
import MainTemplate from '@/v3/templates/MainTemplate';
import {mapGetters} from "vuex";
import Siren from "super-siren";
import HrNavigation from "@/v3/components/navigation/HrNavigation.vue";
import DefaultDashboard from "@/v3/components/DefaultDashboard.vue";

export default {
    components: {
        DefaultDashboard,
        HrNavigation,
        FetchSirenRoot,
        Loading,
        MainTemplate
    },
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
        }),
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },

    },
    mounted() {
        let follow = Siren.Helper.follow;
    },
    methods: {
        getDarkLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=base_dark"
        },
        getLightLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=base_light"
        }
    }
}
</script>

